<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";



// import Spinner from "vue-simple-spinner";


export default {
  components: {
    Layout,
    PageHeader,
    // Spinner,
  },
  computed: {

  },


  methods: {

  },

  mounted() {
    // Set the initial number of items
    // this.vaAccountTransactionsData = cloneDeep(this.accountsDataFromStore);
  },

  created() {
    // this.refreshRecord();
    // this.vaAccountTransactionsData = cloneDeep(this.accountsDataFromStore);
  },

  data() {
    return {
      // TODO: Fetch channel and method data by APIs

    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- Download excel modal -->
    <div>
      <b-modal
        id="modal"
        centered
        v-model="showmodal"
        :title="$t('common.download')"
        title-class="text-dark font-18"
        no-close-on-backdrop
        hide-footer
      >
        <form id="modal" action="#" @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="startdate">{{ $t('common.start_date') }}</label>
            <input
              type="date"
              v-model="chaiStatementDate.from"
              class="form-control"
              name="startdate"
            />
          </div>

          <div class="form-group">
            <label for="enddate">{{ $t('common.end_date') }}</label>
            <input
              type="date"
              v-model="chaiStatementDate.to"
              class="form-control"
              name="enddate"
            />
          </div>

          <div class="text-right">
            <!-- <button type="submit" class="btn btn-primary ml-1 btn-sm">
              {{ $t("views.payouts.pdf") }}
            </button> -->
            <b-button class="mr-1" variant="light" size="sm" @click="showmodal = false">{{ $t('button.close') }}</b-button>
            <button type="submit" class="btn btn-primary ml-1 btn-sm">
              {{ $t("views.payouts.excel") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #transaction-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}
</style>